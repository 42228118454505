import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getRealMoneyProfits({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      '/admin/real_money_profits',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
  getRealMoneyProfit({ date }) {
    return axiosIns.get(`/admin/real_money_profits/${date}`)
  },
  saveRealMoneyProfit({ date, amount, withCashRegisterTransaction }) {
    const formData = serialize(snakecaseKeys({
      realMoneyProfit: {
        date,
        amount,
      },
    }))

    return axiosIns.put(
      `/admin/real_money_profits/${date}/save`,
      formData,
      {
        params: snakecaseKeys({
          withCashRegisterTransaction,
        }),
      },
    )
  },
}
