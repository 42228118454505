import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getHourlyCustomerCounts({ date, interval, endDate = null }) {
    return axiosIns.get(
      '/admin/table_summaries/hourly_customer_counts',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
  getTableCountsByCheckAmountRange({ date, interval, endDate = null }) {
    return axiosIns.get(
      '/admin/table_summaries/table_counts_by_check_amount_range',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
  getOverviews({ date, interval, endDate = null }) {
    return axiosIns.get(
      '/admin/table_summaries/overviews',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
}
