import axiosIns from '@/plugins/axios'

export default {
  getDailyReportCloser({ date }) {
    return axiosIns.get(`/admin/daily_report_closers/${date}`)
  },
  saveDailyReportCloser({ date }) {
    return axiosIns.put(`/admin/daily_report_closers/${date}/save`)
  },
}
