import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  initEarnings({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      'admin/timecards/init_earnings',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
  calcWageAmount({ userId, timecards = [] }) {
    return axiosIns.post(
      'admin/timecards/calc_wage_amount',
      serialize(
        snakecaseKeys({
          userId,
          timecards,
        }),
      ),
      { timeout: 4000 },
    )
  },
  saveAll({ timecards = [] }) {
    const formData = serialize(snakecaseKeys({ timecards }))

    return axiosIns.put('admin/timecards/save_all', formData)
  },
}
