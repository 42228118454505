import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getRegularDayOffs() {
    return axiosIns.get('/admin/regular_day_offs')
  },
  saveAllRegularDayOffs({ daysOfWeek = [] }) {
    const formData = serialize(snakecaseKeys({ daysOfWeek }))

    return axiosIns.put('/admin/regular_day_offs/save_all', formData)
  },
}
