import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  saveUnguidedCustomerCount({ date, count }) {
    const formData = serialize(snakecaseKeys({
      unguidedCustomerCount: {
        count,
      },
    }))

    return axiosIns.put(`/admin/unguided_customer_counts/${date}/save`, formData)
  },
}
