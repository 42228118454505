import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getRebateSystems() {
    return axiosIns.get('/admin/rebate_systems')
  },
  createRebateSystem({
    name,
    description,
    bootTimeAt,
    rebateSystemUsersAttributes,
    rebateSystemUnitOrchestrationsAttributes,
  }) {
    const formData = serialize(snakecaseKeys({
      rebateSystem: {
        name,
        description,
        bootTimeAt,
        rebateSystemUsersAttributes,
        rebateSystemUnitOrchestrationsAttributes,
      },
    }))

    return axiosIns.post('/admin/rebate_systems', formData)
  },
  updateRebateSystem({
    id,
    name,
    description,
    bootTimeAt,
    rebateSystemUsersAttributes,
    rebateSystemUnitOrchestrationsAttributes,
  }) {
    const formData = serialize(snakecaseKeys({
      rebateSystem: {
        id,
        name,
        description,
        bootTimeAt,
        rebateSystemUsersAttributes,
        rebateSystemUnitOrchestrationsAttributes,
      },
    }))

    return axiosIns.put(`/admin/rebate_systems/${id}`, formData)
  },
  deleteRebateSystem(id) {
    return axiosIns.delete(`/admin/rebate_systems/${id}`)
  },
  initEarnings({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      'admin/rebate_systems/init_earnings',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
      { timeout: 8000 },
    )
  },
}
