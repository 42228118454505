import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getDailyClubEvent({ date }) {
    return axiosIns.get(`/admin/daily_club_events/${date}`)
  },
  saveDailyClubEvent({ date, clubEventTagName }) {
    const formData = serialize(snakecaseKeys({
      clubEventTag: {
        name: clubEventTagName,
      },
    }))

    return axiosIns.put(`/admin/daily_club_events/${date}/save`, formData)
  },
  destroyDailyClubEvent({ date }) {
    return axiosIns.delete(`/admin/daily_club_events/${date}`)
  },
}
