import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getEarnings({ date, interval, endDate = null }) {
    return axiosIns.get('/admin/earnings', {
      params: snakecaseKeys({
        date,
        interval,
        endDate,
      }),
    })
  },
  saveAll({ earnings = [], isBeforehandPayment = false }) {
    const formData = serialize(snakecaseKeys({ earnings }))

    return axiosIns.put(
      'admin/earnings/save_all',
      formData,
      {
        params: snakecaseKeys({
          isBeforehandPayment,
        }),
      },
    )
  },
  deleteEarning(id) {
    return axiosIns.delete(`/admin/earnings/${id}`)
  },
  getResult({ date, interval, endDate = null }) {
    return axiosIns.get('/admin/earnings/result', {
      params: snakecaseKeys({
        date,
        interval,
        endDate,
      }),
    })
  },
  getOrphans({ date, interval, endDate = null }) {
    return axiosIns.get('/admin/earnings/orphans', {
      params: snakecaseKeys({
        date,
        interval,
        endDate,
      }),
    })
  },
}
