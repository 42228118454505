import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getDailyWeather({ date }) {
    return axiosIns.get(`/admin/daily_weathers/${date}`)
  },
  saveDailyWeather({ date, weather }) {
    const formData = serialize(snakecaseKeys({
      dailyWeather: {
        weather,
      },
    }))

    return axiosIns.put(`/admin/daily_weathers/${date}/save`, formData)
  },
}
